import React from "react";
import {  FaChartLine, FaUsers, FaFigma , FaLightbulb, FaDocker , FaReact, FaNodeJs, FaHtml5, FaCss3Alt, FaJs, FaDotCircle  } from "react-icons/fa";
import { SiMicrosoftazure,SiBlazor  } from "react-icons/si";
import { GoFileDirectory } from "react-icons/go";
import { BiHappy } from "react-icons/bi";
import { LiaUserFriendsSolid } from "react-icons/lia";
import { CgHello } from "react-icons/cg";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const About = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const SkillItem = ({ skillName, icon: Icon }) => {
    return (
      <div className="skill-item">
        <Icon />
        <span>{skillName}</span>
      </div>
    );
  };

  const EducationItem = ({ title, school, period }) => {
    return (
      <div className="education-item">
        <div>
          <h4 className="bold">{title}</h4>
          <p className="education-school">{school}</p>
        </div>         
        <div>
          <p className="education-period">{period}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="about">
      <div className="about-content" >
        {/* About Me */}
        <div className="about-text" data-aos="fade-up" data-aos-duration="900">
          <div className="small-title">About Me</div>
          <h2 className="color-primary title-section bold">Who Am I?</h2>
          <p className="top-15 text-section">
          As a dynamic and creative person, I possess a passion for continuously exploring new fields and expanding my horizons. My insatiable curiosity fuels my drive to learn and adapt in fast-paced environments, making me an ideal candidate for innovative projects and challenging opportunities.
          </p>  
          <div className="top-50">
            <Link to="/projects" className="projects-btn">My Projects</Link>
          </div>
        </div>

        {/* Education */}
        <div className="education-container" data-aos="fade-up" data-aos-duration="900">
          <div className="small-title">Education</div>
          <h3 className="title-section bold">My Academic Journey</h3>
          <div className="education-timeline top-15">
            <EducationItem title="Postgraduate IT Management" school="HoGent" period="2024-2025" />
            <EducationItem title="Bachelor in Applied Computer Science" school="HoGent" period="2021 – 2024" />
            <EducationItem title="Erasmus Semester" school="NTNU, Norway" period="2023" />
            <EducationItem title="Information Systems Management" school="GO! Atheneum Oudenaarde" period="2019 – 2021" />
            <EducationItem title="Science Mathematics" school="Bernardus college Oudenaarde" period="2015 – 2019" />
          </div>
        </div>

        {/* Skills */}
        <div className="skills-container" data-aos="fade-up" data-aos-duration="900">
          <div className="small-title">Skills</div>
          <h3 className="color-primary title-section bold">Hard Skills</h3>
          <div className="skills-grid ">
            <SkillItem skillName="React" icon={FaReact} />
            <SkillItem skillName="Node.js" icon={FaNodeJs} />
            <SkillItem skillName="HTML" icon={FaHtml5} />
            <SkillItem skillName="CSS" icon={FaCss3Alt} />
            <SkillItem skillName="Javascript" icon={FaJs} />
            <SkillItem skillName=".NET 8" icon={FaDotCircle} />
            <SkillItem skillName="Blazor" icon={SiBlazor} />
            <SkillItem skillName="Docker" icon={FaDocker} />
            <SkillItem skillName="Active Directory" icon={GoFileDirectory} />
            <SkillItem skillName="React Native" icon={FaReact} />
            <SkillItem skillName="Figma" icon={FaFigma} />
            <SkillItem skillName="Microsoft Azure" icon={SiMicrosoftazure} />
          </div>

          <h3 className="color-primary title-section bold">Soft Skills</h3>
          <div className="skills-grid">
            <SkillItem skillName="Problem Solving" icon={FaChartLine} />
            <SkillItem skillName="Teamspirit" icon={FaUsers} />
            <SkillItem skillName="Creativity" icon={FaLightbulb} />
            <SkillItem skillName="Proud" icon={BiHappy} />
            <SkillItem skillName="Friendly" icon={LiaUserFriendsSolid} />
            <SkillItem skillName="Extrovert" icon={CgHello} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;