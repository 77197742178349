import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa"; 
//email & tel icons
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlineCall } from "react-icons/md";

const Footer = () => {
  return (
    <footer>
    <div className="footer-part">
      <div>
        <div className="footer-part-title">Casper De Bock</div>
        <div className="footer-part-text">Vaddenhoek 8</div>
        <div className="footer-part-text">9772 Wannegem-Lede</div>
        <div className="footer-part-text">Belgium</div>
      </div>
     
    </div>
    <div className="footer-part">
      <div>
      <div className="footer-part-title">Contact</div>
        <div className="footer-part-text"><MdOutlineCall/> +32 468 17 12 64</div>
        <div className="footer-part-text"><MdOutlineEmail/> casper.debock@gmail.com</div>
      </div>
    </div>
    <div className="footer-part">
        <div>
          <div className="footer-part-title">Follow Me</div>
        <div className="footer-part-text">
          <a href="https://github.com/CasperDeBock" target="_blank">
            <FaGithub /> Github
          </a>
        </div>
        <div className="footer-part-text">
          <a href="https://www.linkedin.com/in/casperdebock/" target="_blank">
            <FaLinkedin /> LinkedIn
          </a>
        </div>
        <div className="footer-part-text">
          <a href="https://www.facebook.com/casper.debock/" target="_blank">
            <FaFacebook /> Facebook
          </a>
          </div>
          <div className="footer-part-text">
            <a href="https://www.instagram.com/casperdebock" target="_blank">
              <FaInstagram /> Instagram
            </a>
            </div>
          </div>
    </div>
  
  </footer>
  );
};

export default Footer;
