// pages/projects.jsx
import projectsData from '../../data/projects.json';
import pic6 from '../../images/projectimg/jhd.webp'
import pic5 from '../../images/projectimg/cdl.webp'
import pic4 from '../../images/projectimg/gentevt.webp'
import pic7 from '../../images/projectimg/ec.webp'
import pic8 from '../../images/projectimg/bob.webp'
import pic1 from '../../images/projectimg/isy.webp'
import pic2 from '../../images/projectimg/gerania.webp'
import pic3 from '../../images/projectimg/scoutsweb.webp'
import AOS from 'aos';
import { useEffect } from 'react';
import "aos/dist/aos.css";


export default function Projects() {
    window.scrollTo(0, 0);
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        window.scrollTo(0, 0);
      }, []);
  return (
  
    <div className="page-container">
        <div className="project-text" data-aos="fade-up" data-aos-duration="900">
            <div className="small-title">Projects</div>
            <h2 className="color-primary title-section bold">My Work</h2>
            <p className="top-15 text-section">
                Here are some of the projects I have worked on. Click on the project to view more details.
            </p>  
        </div>

        <ProjectsGrid projects={projectsData.projects} />
    </div>
 
  );
}

// components/ProjectsGrid.jsx
const ProjectsGrid = ({ projects }) => {
    return (
      <div className="project-container">
        <div className="project-grid">
          {projects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      </div>
    );
  };


const ProjectCard = ({ project }) => {

    window.scrollTo(0, 0);
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        window.scrollTo(0, 0);
      }, []);
    const pic = [pic1,pic2,pic3,pic4,pic5,pic6,pic7,pic8]


  return (
    <div className="project-card overflow-hidden"  data-aos="fade-up" data-aos-duration="500"> 
      {/* Image Carousel */}
      <div className="relative h-48">
        <img
          src={pic[project.id]}
          alt={project.altTag}
          className="w-full h-full object-cover project-img"
        />
      </div>

      {/* Content */}
      <div className="p-4">
        <h3 className="text-xl font-bold mb-2 project-title">{project.name}</h3>
        <p className="text-gray-600 mb-4 line-clamp-3">{project.description}</p>
       
        <a
          href={project.linkfromweb}
          target="_blank"
          rel="noopener noreferrer"
          className="project-link"
        >
          Visit
        </a>
      </div>
    </div>
  );
};