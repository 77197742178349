import React from "react";
import LandingImage from "../../images/CasperDeBock.png";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";  
import { useEffect } from "react";  
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Landing = () => {
    window.scrollTo(0, 0);

useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scrollTo(0, 0);

    }, []);

  return (
    <div className="landing">
        <div className="landing-text">
            <div className="landing-text-title" data-aos="fade-up" data-aos-duration="900">
                I'm Casper, a software developer.
            </div>
            <div className="landing-text-subtitle" data-aos="fade-up" data-aos-duration="900">
                I will help you create your digital solution for all your problems!
            </div>
            <div className="landing-text-socials" data-aos="fade-up" data-aos-duration="900">
                <a href="https://github.com/CasperDeBock" target="_blank">
                    <FaGithub />
                </a>    
                <a href="https://www.linkedin.com/in/casperdebock/" target="_blank">
                    <FaLinkedin />
                </a>
                <a href="https://www.facebook.com/casper.debock/" target="_blank">
                    <FaFacebook />
                </a>
                <a href="https://www.instagram.com/casperdebock" target="_blank">
                    <FaInstagram />
                </a>
            </div>
            <div className="landing-text-button" data-aos="fade-right" data-aos-duration="900">
                <Link to="/contact">LETS CHAT!</Link>
            </div>
        </div>
        <div className="landing-image" >
            <img src={LandingImage} alt="Casper De Bock" data-aos="fade-right" data-aos-duration="900" />
        </div>
        <div className="landing-animation"></div>
        <div className="landing-line">
            </div>
    </div>
  );
};

export default Landing;
