import React from "react";
import Landing from "./Landing.jsx";
import About from "./About.jsx";

const Homepage = () => {
  window.scrollTo(0, 0);
  return (
    <>
        <Landing />
        <About />
    </>
  );
};

export default Homepage;
