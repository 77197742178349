import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";

const NavLink = ({ label, to, isActive, onClick }) => (
  <Link
    onClick={onClick}
    className={`nav-link left-25 ${isActive ? "nav-active" : ""}`}
    to={to}
  >
    {label}
  </Link>
);

const Navbar = () => {
  const [active, setActive] = useState("home");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLang, setIsOpenLang] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavLinkClick = (page) => {
    setActive(page);
    setIsOpen(false); // Close the menu when a link is clicked
  };

  window.addEventListener('scroll', function() {
    const navbar = document.querySelector('nav');
    if (window.scrollY > 0) {
      navbar.classList.add('navbar-scrolled');
    } else {
      navbar.classList.remove('navbar-scrolled');
    }
  });

  return (
    <nav>
      <div className="nav-logo">
        <Link
          onClick={() => handleNavLinkClick("home")}
          className={`nav-link ${active === "home" ? "nav-active" : ""}`}
          to="/"
        >
          <span className="">Casper De Bock</span>
        </Link>
      </div>
      <div className="next">
        <div className="next">
          <div className="nav-right">
            <NavLink
              label={"Home"}
              to="/"
              isActive={active === "home"}
              onClick={() => handleNavLinkClick("home")}
            />
            <NavLink
              label="Projects"
              to="/projects"
              isActive={active === "projects"}
              onClick={() => handleNavLinkClick("projects")}
            />
            <NavLink
              label={"Contact"}
              to="/contact"
              isActive={active === "contact"}
              onClick={() => handleNavLinkClick("contact")}
            />
          </div>
         
        <div className="ham-menu">
          <div className="nav-menu-icon" onClick={toggleMenu}>
            {isOpen ? <IoMdClose /> : <GiHamburgerMenu />}
          </div>
          <div className={`nav-popup ${isOpen ? "open" : ""}`}>
            <div className="nav-popup-upper">
              <div className="nav-popup-up">
                <div className="nav-popup-title">
                  <span className="">Casper De Bock</span>
                </div>
              </div>
              <NavLink
                label={"Home"}
                to="/"
                isActive={active === "home"}
                onClick={() => handleNavLinkClick("home")}
              />
              <NavLink
                label={"Projects"}
                to="/projects"
                isActive={active === "projects"}
                onClick={() => handleNavLinkClick("projects")}
              />
              <NavLink
                label={"Contact"}
                to="/contact"
                isActive={active === "contact"}
                onClick={() => handleNavLinkClick("contact")}
              />
            </div>
          </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
